import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import stats3 from "../../images/orkestraal.png"

const lavadSisu = () => (
  <Layout>
    <SEO title="Koori- ja orkestrilavad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>KOORI- JA ORKESTRILAVAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/lavad/mobiilsed-lavad-vali/">
          <div className="epo antistaatilised">
            <h2>Mobiilsed välilavad</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/lavad/mobiilsed-lavad/">
          <div className="epo epohoordemass">
            <h2>Mobiilsed lavad</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/lavad/mobiilsed-tribyynid/">
          <div className="epo isetasanduv-epomasskate">
            <h2>Mobiilsed tribüünid</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link
          to="/lavad/orkestraal/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo treppidele">
            <h2>Koori- ja orkestrilavad</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/lavad/ratastel/">
          <div className="epo dekoratiivporandad">
            <h2>Ratastel lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/soiduki-lavad/">
          <div className="epo dekoratiivporandad">
            <h2>Sõidukite lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/statsionaarsed-tribyynid/">
          <div className="epo dekoratiivporandad">
            <h2>Statsionaarsed tribüünid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/statsionaarsed/">
          <div className="epo dekoratiivporandad">
            <h2>Statsionaarsed lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/teleskoop-tribyynid/">
          <div className="epo dekoratiivporandad">
            <h2>Teleskooptribüünid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
      </div>

      <div className="antistaatilised-wrapper">
        <div className="antistaatilised-text">
          <h3>Koori- ja orkestrilavad</h3>
          <p>
            Iga koorilava või koori stendi saab rakendada erinevatel nõlvadel
            ning vastavalt individuaalsetele nõuetele ja ruumitingimustele.
          </p>
        </div>
        <div className="subpage-img platvorm-img">
          <img src={stats3} alt="Koori- ja orkestrilavad"></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default lavadSisu
